import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PopUpService } from '../shared/services/pop-up.service';
import { CommentDetails, EventDetails, Media } from '../shared/models/eventDetails';
import { CameraStatus } from '../shared/constants/enum';
import { Subject, catchError, filter, takeUntil, tap } from 'rxjs';
import { FILEAPI, FILE_DOWNLOAD_ERROR } from '../shared/constants/camera-profile-constant';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { TimeZoneService } from '../shared/services/time-zone.service';
import { TimeZone } from '../shared/models/dashabordAlertData';
import { CameraConstants } from '../shared/constants/camera-constant';
import { DashboardService } from '../shared/services/dashboard.service';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { LoginService } from '@agora/agora-ui-library';
import { UserSettingsService } from '../shared/services/user-settings.service';
import { UnitsData } from '../shared/models/unit';

@Component({
  selector: 'app-latest-camera-popover',
  templateUrl: './latest-camera-popover.component.html',
  styleUrls: ['./latest-camera-popover.component.scss'],
})
export class LatestCameraPopoverComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @Input() cameraDetails: EventDetails;
  @Input() hasNext = true;
  @Input() hasPrevious = false;
  @Input() eventIndex = 0;
  @Output() closeSideInfo = new EventEmitter<string>();
  @Output() nextPrevEvent = new EventEmitter<number>();
  public isImage: boolean;
  public isFullscreen = true;
  public currentIndex = 0;
  public currentImageInfo: Media | undefined | null;
  public cameraName: string;
  public cameraStatus: CameraStatus;
  public currentZone = '';
  public media: Media[] = [];
  public currentEventIndex = 0;
  public eventType = '';
  public noImagePreview = false;
  public sessionToken: string;
  public isImgFullScreen: boolean;
  public fullScreeenImgSrc: string;
  public commentDetails: CommentDetails[];
  public redzoneReason: string;
  public fromFullscreen: boolean;
  private destroyed = new Subject();
  private unitSelected: UnitsData;
  private convertedUnit: string;
  private unitReceived: string;

  constructor(
    private popService: PopUpService,
    private timeZoneService: TimeZoneService,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private cameraProfileService: CameraProfileService,
    private loginService: LoginService,
    private userSettingService: UserSettingsService
  ) {
    this.userSettingService.unitSelected$
      .pipe(
        tap((value: string) => {
          this.unitSelected = JSON.parse(value);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sessionToken = sessionStorage.getItem('access_token') ?? '';
    if (changes['cameraDetails'] && changes['cameraDetails'].currentValue) {
      this.currentIndex = 0;
      this.currentEventIndex = this.eventIndex;
      this.media = [];
      this.commentDetails = [];
      if (
        this.cameraDetails.peopleInsideRedZone > 0 &&
        this.cameraDetails.comment !== '' &&
        this.cameraDetails?.groupId !== 'bsp' &&
        this.cameraDetails?.groupId !== 'ksa'
      ) {
        this.constructComment();
      }
      if (this.cameraDetails.media.length > 0) {
        const mediaData = this.cameraDetails.media.filter(m => m.type === 'image' || (m.type === 'video' && m.fileUrl));
        this.media = mediaData;
        this.currentImageInfo = this.cameraDetails.media[this.currentIndex];
        this.noImagePreview = this.cameraDetails?.media.filter(m => m?.fileUrl).length > 0 ? false : true;
        if (this.media.length === 2) {
          const imgData = mediaData.filter(data => data.type === 'image');
          const vidData = mediaData.filter(data => data.type === 'video');
          this.media = JSON.parse(JSON.stringify([...imgData, ...vidData]));
          if (imgData?.[0]?.fileUrl?.includes('mp4')) {
            this.media[1].fileUrl = imgData[0].fileUrl;
          }
          if (!vidData?.[0]?.fileUrl?.includes('mp4')) {
            this.media[0].fileUrl = vidData[0].fileUrl;
          }
          if (!this.media[0].fileUrl && this.media[0].type === 'image') {
            this.currentIndex = 1;
          }
        }
        this.validateMediaUrl();
      } else {
        this.media.push({ type: 'image', fileUrl: '' });
        this.noImagePreview = this.media?.filter(m => m?.fileUrl && m.fileUrl !== '')?.length > 0 ? false : true;
      }

      if (this.isFullscreen) {
        this.isFullscreen = !this.noImagePreview ? this.isFullscreen : !this.isFullscreen;
      }
      if (Number(this.cameraDetails?.cameraStatus) === CameraStatus.Active) {
        this.cameraStatus = CameraStatus.Active;
      } else if (Number(this.cameraDetails?.cameraStatus) === CameraStatus.Inactive) {
        this.cameraStatus = CameraStatus.Inactive;
      } else {
        this.cameraStatus = CameraStatus.Disconnected;
      }
      if (this.cameraDetails.peopleInsideRedZone === 0 && this.cameraDetails.ppeViolations && this.cameraDetails.ppeViolations > 0) {
        this.eventType = CameraConstants.PpeViolation;
      } else {
        this.eventType = CameraConstants.RedZoneViolation;
      }
    }
  }

  ngAfterViewInit(): void {
    this.timeZoneService.timeZoneDetails$
      .pipe(
        filter((zone: TimeZone) => !!Object.keys(zone).length),
        tap((_zone: TimeZone) => (this.currentZone = this.timeZoneService.getTimeZone())),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public onClose(): void {
    this.isFullscreen = false;
    this.closeSideInfo.emit();
  }

  public onNextPrevClick(type: string): void {
    if (type === CameraConstants.next) {
      this.currentEventIndex = this.currentEventIndex + 1;
    } else {
      this.currentEventIndex = this.currentEventIndex - 1;
    }
    this.nextPrevEvent.emit(this.currentEventIndex);
    this.currentIndex = 0;
  }

  public downloadImages(): void {
    const eventId = this.cameraDetails.eventId;
    if (this.cameraDetails?.gatewayId && this.cameraDetails?.controllerId && !this.noImagePreview) {
      let isFileApi = false;
      if (this.cameraDetails?.media?.length > 0) {
        isFileApi = this.cameraDetails?.media.filter(url => url.fileUrl.includes(FILEAPI))?.length > 0;
      }
      this.cameraProfileService
        .downloadMediaAsZip(this.cameraDetails.gatewayId, this.cameraDetails.controllerId, [eventId], isFileApi)
        .pipe(
          tap((data: string) => {
            if (data && data !== '' && this.cameraDetails?.controllerId) {
              this.dashboardService.downloadZipFile(data, this.cameraDetails?.controllerId);
            } else {
              this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            }
          }),
          catchError(err => {
            this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
            throw err;
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    } else {
      this.messageService.add({ severity: SlbSeverity.Error, summary: FILE_DOWNLOAD_ERROR });
    }
  }

  public openEmail(): void {
    const a = document.createElement('a');
    a.href = 'mailto:';
    a.click();
  }

  public toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;
  }

  public async openReview(cameraDetails: EventDetails): Promise<void> {
    if (cameraDetails) {
      const arrEventDetails: EventDetails[] = [cameraDetails];
      await this.popService.openEditReviewConfiguration(arrEventDetails);
    }
  }

  public getCurrentIndex(event: { activeSlideIndex: number; totalSlides: number }): void {
    this.currentIndex = event.activeSlideIndex;
  }

  public setCurrentIndex(event: number): void {
    this.currentIndex = event;
  }

  public showImageInFullscreen(imgSrc: string): void {
    this.fullScreeenImgSrc = imgSrc + '/?token=' + this.sessionToken;
    this.isImgFullScreen = true;
  }

  public hideImageInFullscreen(): void {
    this.fullScreeenImgSrc = '';
    this.isImgFullScreen = false;
  }

  ngOnDestroy(): void {
    this.isImgFullScreen = false;
    this.fullScreeenImgSrc = '';
    this.destroyed.next(true);
  }

  private validateMediaUrl(): void {
    const mediaData = this.media;
    mediaData.forEach(el => {
      if (el.fileUrl) {
        const requestUrl = new URL(el.fileUrl);
        requestUrl.searchParams.append('token', this.sessionToken);
        fetch(requestUrl.toString())
          .then(res => {
            if (res.status !== 200) {
              /** When token is expired, redirect to login page*/
              if (res.status === 403) {
                this.loginService.login();
              }
              this.removeInvalidMedia(el.type);
            }
          })
          .catch(() => {
            this.removeInvalidMedia(el.type);
          });
      }
    });
  }

  private removeInvalidMedia(type: string): void {
    if (type === 'video') {
      this.media = this.media.filter(m => m.type !== type);
    } else {
      const index = this.media.findIndex(m => m.type === type);
      if (index !== -1) {
        this.media[index].fileUrl = '';
      }
    }
    if (this.media.length > 1) {
      this.noImagePreview = this.media.filter(m => m?.fileUrl && m.fileUrl !== '').length > 0 ? false : true;
    }
  }

  private constructComment(): object {
    this.cameraDetails.comment
      .replace(/^\[(.+)\]$/, '$1')
      .split('),')
      .forEach((data, i) => {
        if (i > 0) {
          const keyValue = data?.split("'")?.[1]?.trim()?.split(':');
          const testRegex = /[&><|]/;
          if (!(keyValue.length > 3 || keyValue?.[0].includes('=') || testRegex.test(keyValue?.[1]))) {
            let finalObj: CommentDetails = {
              key: '',
              value: '',
              color: '',
            };
            if (keyValue.length > 2) {
              keyValue[1] = keyValue[1] + ' ' + keyValue[2];
              keyValue.pop();
            }
            const segregateKey = keyValue?.[0].split(' ');
            this.unitReceived = JSON.parse(JSON.stringify(segregateKey?.[1]));
            finalObj.key = this.formatKey(segregateKey);
            finalObj = this.formatValue(keyValue?.[1], segregateKey?.[1], finalObj);
            this.commentDetails.push(finalObj);
          }
        }
      });

    return this.commentDetails;
  }

  /** Construct key of the comment json  */
  private formatKey(segregateKey: string[]): string {
    let keyValue = '';
    if (segregateKey.length > 0) {
      segregateKey[1] = this.userSettingService.getUnit(segregateKey[1], this.unitSelected.unit_data);
      this.convertedUnit = segregateKey[1];
      keyValue = segregateKey.join(' ');
    }

    return keyValue;
  }

  /** Construct value of the comment json  */
  private formatValue(value: string, unitSelected: string, finalObj: CommentDetails): CommentDetails {
    const splitValue = this.removeEmpty(value.trim().split(' '));
    const minMaxArray: number[] = JSON.parse(this.getMinMaxValue(splitValue)).sort();
    const currValue = parseFloat(splitValue[splitValue.length - 1]);
    const formattedCurrValue = this.userSettingService.siToChosenUnit(currValue, this.unitReceived, unitSelected);
    const chosenUnitsminMax = minMaxArray.map((sortValue: number) =>
      this.userSettingService.siToChosenUnit(sortValue, this.unitReceived, unitSelected)
    );
    finalObj.color = this.checkMinMax(chosenUnitsminMax, formattedCurrValue);
    splitValue.splice(0, 2);
    splitValue[splitValue.length - 1] = formattedCurrValue.trim();
    finalObj.value = '[' + chosenUnitsminMax[0] + ', ' + chosenUnitsminMax[1] + '] ' + splitValue.join(' ');

    return finalObj;
  }

  private checkMinMax(minMax: string[], value: string): string {
    let color = 'red';
    if (Number(minMax?.[0]) <= Number(value) && Number(minMax?.[1]) >= Number(value)) {
      color = 'green';
    }

    return color;
  }

  private getMinMaxValue(splitValue: string[]): string {
    const regex = /[+-]?\b\d+(\.\d+)?\b/g;
    if (!regex.test(splitValue?.[0])) {
      splitValue[0] = '[0.0,';
    } else if (regex.test(splitValue?.[1])) {
      splitValue[1] = '0.0]';
    }
    const mergeArray = splitValue?.[0] + '' + splitValue?.[1];

    return mergeArray;
  }

  private removeEmpty(splittedArray: string[]): string[] {
    return splittedArray.filter(arr => arr !== '');
  }
}
