export const environment = {
  production: true,
  apiBaseUrl: 'https://evp-safety.agoraiot.app',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl:'https://csi.slb.com/v2',
  identityProviderClientKey: '447af2c3756a47698c1da11176970a6d',
  identityProviderClientSecret:'e9635cfbb92d4198b79f4bed5fdb9464d6ced5c7bc2e83',
  identityProviderRedirectUrl: 'https://evp-safety.agoraiot.app/login/logincallback'
};
